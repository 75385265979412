export const nav = () => {
  const navBtn = document.querySelector('.nav__button');
  const nav = document.querySelector('.nav');
  const body = document.querySelector('body');

  if (navBtn) {
    navBtn.addEventListener('click', function (evt) {
      evt.preventDefault();
      nav.classList.toggle('is-open');
      body.classList.toggle('scroll-lock');

      if (nav.closest('.is-open')) {
        const navBtnMenuOpen = document.querySelectorAll('.nav.is-open .nav__submenu-link');
        if (navBtnMenuOpen) {
          navBtnMenuOpen.forEach((elem) => {
            elem.addEventListener('click', function () {

              let parentElem = this.parentNode;

              if (parentElem.classList.contains("is-open")) {
                parentElem.classList.remove('is-open');
              } else {
                let navSubmenu = document.querySelectorAll('.nav__submenu');

                navSubmenu.forEach((elem) => {
                  elem.classList.remove('is-open');
                })

                parentElem.classList.add('is-open');
              }
            })
          })
        }
      }
    })
  }
};
