import { Fancybox } from '../vendor/fancybox.umd';
export const fancyimg = () => {
  if (document.querySelector('.chapter')) {
    Fancybox.bind('[data-fancybox="gallery"]', {
      Thumbs: {
        Carousel: {
          fill: false,
          center: true,
        },
      },
    });
  }
}
